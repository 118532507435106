.categories {
  padding: 50px var(--body-padding);
  padding-bottom: 150px;

  &__modal {
    background-color: #fff;
    padding: 30px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // gap: 20px;
    position: relative;

    &__img {
      width: 450px;
    }

    &__close {
      position: absolute;
      right: 10px;
      top: 5px;
      cursor: pointer;
      border: 1px solid #000;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      & span {
        font-size: 30px;
        line-height: 25px;
        font-weight: 300;
        display: inline-block;
        transform: rotate(45deg);
        transition: 0.3s;
        position: relative;
        left: 1px;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
    gap: 10px;

    & img {
      height: 13px;
    }
  }

  &__top {
    display: flex;
    gap: 30px;
    justify-content: center;
    font-size: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 20px;
    // overflow: auto;

    // @media screen and (max-width: 800px) {
    // font-size: 17px;
    flex-wrap: wrap;
    gap: 20px;
    // }

    &__item {
      display: inline-block;
      padding: 8px 20px;
      //   background-color: $tetiary-color;
      //   color: white;
      //   border: 1px solid $primary-color;
      border-radius: 40px;
      cursor: pointer;
      font-size: 15px;
      text-transform: uppercase;
      transition: 0.3s;

      // @media screen and (max-width: 800px) {
      background-color: rgba(254, 108, 30, 0.083);
      // }
      &.active {
        color: $primary-color;
      }
      &:hover {
        color: $primary-color;
      }
    }
  }

  &__main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;

    @media screen and (max-width: 1000px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 700px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 500px) {
      grid-template-columns: repeat(1, 1fr);
    }

    &__item {
      cursor: pointer;
      & img {
        border-radius: 10px;
        width: 100%;
        height: 150px;
        object-fit: cover;
        border: 1px solid #ececec;
      }

      & p {
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
