.hero {
  min-height: 700px;
  //   background-image: url(../../../images/hero.jpg);
  //   background-size: contain;
  //   background-position: center;
  //   background-repeat: no-repeat;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 30px;
  background-color: $tetiary-color;
  //   background-color: $primary-color;
  padding: 50px var(--body-padding);

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }

  &__main {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    & p {
      max-width: 550px;
      // font-family: 'Playfair Display', serif;
    }

    &__btn {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 30px;
      @media screen and (max-width: 500px) {
        flex-wrap: wrap;
      }

      //   @media screen and (max-width: 600px) {
      //     flex-wrap: wrap;
      //   }

      &__item {
        height: 60px;
        width: 210px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        gap: 20px;
        background-color: transparent;
        padding: 0 20px;
        border-radius: 20px;
        cursor: pointer;
        transition: 0.3s;

        @media screen and (max-width: 500px) {
          width: 150px;
          height: 50px;
          padding: 0 10px;
          gap: 10px;
        }

        & span {
          font-size: 23px;

          @media screen and (max-width: 500px) {
            font-size: 18px;
          }
        }
        &__img {
          height: 35px;
          @media screen and (max-width: 500px) {
            height: 20px;
          }

          &.alt {
            height: 50px;

            @media screen and (max-width: 500px) {
              height: 30px;
            }
          }
        }

        &:hover {
          border-color: $primary-color;
        }
      }
    }
  }

  &__img {
    & img {
      width: 100%;
      border-radius: 50px;
      animation: ripple 4.5s linear infinite alternate;
      //   box-shadow: 0 0px 20px rgba(36, 39, 37, 0.3);
    }
  }
}

@keyframes ripple {
  0% {
    border-radius: 48% 52% 57% 43% / 34% 44% 56% 66%;
  }
  25% {
    border-radius: 62% 38% 64% 36% / 34% 55% 45% 66%;
  }
  50% {
    border-radius: 43% 57% 51% 49% / 33% 42% 58% 67%;
  }
  100% {
    border-radius: 40% 60% 43% 57% / 52% 32% 68% 48%;
  }
}
