.footer {
  padding: 0 var(--body-padding);

  & a {
    transition: 0.3s;

    &:hover {
      color: $primary-color;
    }
  }
  &__main {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr;
    gap: 30px;
    padding: 80px 0;
    border-bottom: 1px solid #dbdbdb;

    @media screen and (max-width: 900px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    &__logo {
      & img {
        width: 100%;
        max-width: 230px;
        margin-bottom: 10px;
      }

      &__links {
        display: flex;
        gap: 20px;
        margin-top: 15px;
        align-items: center;

        & i {
          font-size: 20px;
          transition: 0.3s;
          color: $primary-color;
          font-weight: bold;

          // &:hover {
          //   color: $primary-color;
          // }
        }
      }
    }

    &__item {
      & h4 {
        margin-bottom: 30px;
        font-size: 25px;
        font-weight: 400;
      }
      & li {
        margin-bottom: 15px;
      }

      &__info {
        margin-bottom: 15px;
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
  }

  &__bottom {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    // font-size: 14px;
    gap: 5px;

    & span {
      color: $primary-color;
    }
  }
}
