.terms {
  padding: 50px var(--body-padding);
  line-height: 1.5;

  & p {
    font-size: 16px;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 10px;
  }

  & h2 {
    margin-bottom: 10px;
  }
  & h5 {
    font-size: 20px;
    margin-top: 30px;
  }
}
