.feature {
  background-color: $tetiary-color;
  padding: 50px var(--body-padding);

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    text-align: center;

    & p {
      max-width: 900px;
      line-height: 25px;
    }
  }

  &__main {
    display: grid;
    grid-template-columns: 2fr 3fr;
    align-items: center;

    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      margin-bottom: 40px;
    }

    &.alt {
      grid-template-columns: 3fr 2fr;
      @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
        flex-direction: column;
      }
    }

    & img {
      width: 100%;
      @media screen and (max-width: 800px) {
        max-width: 500px;
        margin: 30px 0;
      }
    }

    & h3 {
      margin-bottom: 20px;
    }

    & p {
      line-height: 30px;
    }
  }
}
