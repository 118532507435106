.privacy {
  padding: 50px var(--body-padding);
  line-height: 1.5;

  & p {
    font-size: 16px;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 10px;
  }

  & h2 {
    margin-bottom: 10px;
  }
  & h5 {
    font-size: 20px;
    margin-top: 30px;
  }

  .table {
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    // border-color: #dee2e6;

    & li {
      border-top: 1px solid black;
      border-right: 1px solid black;
      padding: 5px 10px;
    }
  }
}
