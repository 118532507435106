.topbar {
  padding: 20px 0;
  padding-bottom: 0;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // gap: 20px;
    margin-bottom: 30px;
    padding: 0 var(--body-padding);

    &__logo {
      width: 270px;
      @media screen and (max-width: 600px) {
        width: auto;
      }
      & img {
        height: 60px;
      }
    }

    &__right {
      width: calc(100% - 270px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 1050px) {
        justify-content: flex-end;
      }
      @media screen and (max-width: 600px) {
        width: auto;
      }
    }

    &__call {
      display: flex;
      align-items: center;

      & i {
        color: $primary-color;
        font-size: 40px;
        padding-top: 5px;
        padding-right: 10px;

        @media screen and (max-width: 600px) {
          font-size: 25px;
        }
      }

      &__left {
        & p {
          font-size: 14px;
          color: #777;
          margin-bottom: 5px;

          @media screen and (max-width: 600px) {
            font-size: 12px;
          }
        }

        & a {
          color: $text-color;
          font-weight: 600;
          font-size: 22px;

          @media screen and (max-width: 600px) {
            font-size: 18px;
          }
        }
      }
    }

    &__search {
      position: relative;
      @media screen and (max-width: 1050px) {
        display: none;
      }

      & input {
        border: 2px solid $primary-color;
        // border: 2px solid rgb(230, 230, 230);
        width: 40%;
        width: 366px;
        height: 51px;
        font-size: 14px;
        padding: 0 20px;
        padding-right: 50px;
        border-radius: 50px;
      }

      & i {
        position: absolute;
        font-size: 20px;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        // color: $primary-color;
        font-weight: bold;
      }
    }
  }

  &__bottom {
    display: flex;
    // gap: 20px;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--body-padding);

    &.alt {
      opacity: 0;
      position: fixed;
      left: 0;
      top: -200px;
      width: 100%;
      background-color: #fff;
      z-index: 100;
      transition: 1s ease-out;
      webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.13);
      box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.13);
    }

    &.fixed {
      opacity: 1;
      top: 0;
    }

    &__left {
      display: flex;
      align-items: center;
      //   gap: 30px;
      // height: 100%;
    }

    &__cat {
      min-width: 230px;
      display: flex;
      flex-direction: column;
      position: relative;
      // height: 60px;
      // height: 100%;
      position: relative;
      top: 1px;

      &__top {
        cursor: pointer;
        background-color: $primary-color;
        padding: 15px 20px;
        padding-top: 17px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        color: #fff;
        display: flex;
        align-items: center;
        // gap: 10px;
        justify-content: space-between;
        z-index: 3;

        &__left {
          display: flex;
          align-items: center;
          //   gap: 10px;
          font-size: 18px;
          font-weight: 700;

          & i {
            font-size: 18px;
            font-weight: 700;
            margin-right: 10px;
          }
        }
        &__right {
          & i {
            font-size: 14px;
          }
        }
      }

      &__list {
        z-index: 3;
        background-color: #fff;
        position: absolute;
        left: 0;
        // bottom: calc(-256px + 73px);
        top: 52px;
        border: 2px solid $primary-color;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        border-top: none;
        padding: 0 10px;
        height: 0;
        transition: 0.5s ease-out;
        overflow: hidden;
        width: 100%;

        &.open {
          height: 256px;
          // border-width: 2px;
        }

        & li {
          padding: 10px;
          font-weight: 600;
          &:not(:last-child) {
            border-bottom: 1px solid #d9d9d9;
          }
          transition: 0.3s;
          cursor: pointer;

          &:hover {
            color: $primary-color;
          }

          & img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            object-fit: cover;
            border: 1px solid #d9d9d9;
          }
        }
      }
    }

    &__links {
      margin-left: 40px;
      display: flex;
      align-items: center;
      gap: 40px;
      padding-top: 2px;

      @media screen and (max-width: 1150px) {
        width: auto;
        gap: 20px;
      }
      @media screen and (max-width: 900px) {
        display: none;
      }

      &__item {
        font-weight: 400;
        text-transform: uppercase;
        transition: 0.3s;

        &:hover {
          color: $primary-color;
        }
      }
    }

    &__menu {
      cursor: pointer;
      transition: 0.3s;
      font-size: 20px;

      &:hover {
        color: $primary-color;
      }
    }
  }
}
