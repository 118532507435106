.partners {
  padding: 50px var(--body-padding);

  & h3 {
    margin-bottom: 20px;
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //   background-color: $tetiary-color;
    flex-wrap: wrap;

    & img {
      width: 100px;

      @media screen and (max-width: 600px) {
        width: 60px;
      }
    }
  }
}
