:root {
  font-size: 62.5%;
  --body-padding: 15%;
}

@media screen and (max-width: 1200px) {
  :root {
    --body-padding: 9%;
  }
}

@media screen and (max-width: 900px) {
  :root {
    --body-padding: 7%;
  }
}
@media screen and (max-width: 600px) {
  :root {
    --body-padding: 5%;
  }
}

@import "abstracts/variable";

//   @import "layouts/authLayout";

@import "components/topbar";
@import "components/hero";
@import "components/flyers";
@import "components/categories";
@import "components/feature";
@import "components/review";
@import "components/partners";
@import "components/faq";
@import "components/footer";
@import "components/drawer";

@import "pages/notFound";
@import "pages/terms";
@import "pages/privacy";

*,
::before,
::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  color: $text-color;
}

a {
  text-decoration: none;
  color: $text-color;
}

img {
  max-width: 100%;
  height: auto;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $textAlt-color;
}

.btnMain {
  width: 180px;
  height: 50px;
  background-color: $tetiary-color;
  color: #fff;
  border: none;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.btnMainAlt {
  width: 180px;
  height: 50px;
  background-color: $primary-color;
  color: #fff;
  border: none;
  border-radius: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.loadingCon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.suc {
  color: $success-color;
}

.fail {
  color: $error-color;
}

/////////// MODAL STYLES ////////////
.modal-overlayCenter {
  background: rgba(0, 0, 0, 0.5);
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ReactModal__Content {
  &:focus-visible {
    outline: none !important;
  }
}

/////////// PAGINATE ////////////

.paginationBttns {
  padding: 0;
  /* height: 20px; */
  list-style: none;
  display: flex;
  // align-self: flex-end;
  justify-content: center;
  flex-wrap: wrap;
}

.paginationBttns a {
  height: 33px;
  width: 35px;
  margin: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  background-color: rgb(226, 226, 226);
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s;
}

.paginationBttns a:hover {
  color: white;
  background-color: $tetiary-color !important;
}

.paginationActive a {
  color: white;
  background-color: $tetiary-color !important;
}

.paginationDisabled a {
  color: #c3c3c3;
  background-color: transparent;

  &:hover {
    color: #c3c3c3;
    background-color: transparent !important;
  }
}
.pignateCon {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

// @szhsin/react-menu

.szh-menu {
  // color: #fff !important;
  font-size: 12px !important;
  text-transform: uppercase !important;

  & li {
    padding: 10px 20px !important;

    &:hover {
      background-color: $tetiary-color !important;
      color: $primary-color !important;
    }
  }
}
